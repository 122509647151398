﻿//
// Home
//

body.home {

	.header {
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	.sidebar {
		hr {
			border-color: $black;
		}
	}

	#mainContents {
		@include media-breakpoint-down(sm) {
			padding-top: 55px;
		}
	}

	.main-visual-carousel-container {
		display: block;
	}
}
.sidebar {
    hr {
        border-top-width: 1px;
    }
}

.side-nav {
    padding-left: 0;
    list-style: none;

    &__item {
        border-bottom: 1px dashed #dcdcdc;
    }

    &__link {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $body-color;
        transition: $transition-base;

        @include hover {
            color: $body-color;
            text-decoration: none;
            opacity: 0.5;
        }
    }
}
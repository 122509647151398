.wf-sawarabimincho {
	font-family: "Sawarabi Mincho";
	font-weight: $font-weight-normal;
}

.heading-bottom-line {
    border-bottom: 3px solid $gray-300;
    padding-bottom: 1rem;
    text-align: center;
    font-family: $font-family-sawarabi-mincho;
    font-size: $h3-font-size;
    font-weight: $font-weight-normal;
}

.heading-vertical-bar {
    text-align: center;
    font-family: $font-family-sawarabi-mincho;
    font-size: $h3-font-size;
    font-weight: $font-weight-normal;
    
    span {
        display: inline-block;
        margin-bottom: 1rem;
        border-left: 2px solid $black;
        border-right: 2px solid $black;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: $h5-font-size;
    }
}

.list-circle {
    padding-left: 0;
    list-style: none;
    font-size: $h5-font-size;

    &__item {
        position: relative;
        border-bottom: 2px solid $gray-300;
        padding-right: 1.25rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        
        &:before {
            content: '●';
            margin-right: 0.25rem;
        }
        
        &:after {
            content: '';
            position: absolute;
            right: 5px;
            top: 50%;
            display: block;
            margin-top: -7px;
            border-right: 2px solid $gray-300;
            border-top: 2px solid $gray-300;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
        }
    }

    &__link {
        color: $body-color;
        text-decoration: underline;
    
        @include hover {
            text-decoration: none;
        }
    }
}
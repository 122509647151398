#makebanner {
	table-layout: fixed;

	td[width="150"] {
		padding-right: 30px;
		width: 250px;
	}

	td[width="640"],
	td[width="600"] {
		width: 700px;
	}

	td[align="center"] {
		text-align: left;
	}
}
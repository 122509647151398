.btn {
    font-family: $font-family-sawarabi-mincho;

    .small {
        font-size: rem(12);
    }
}

.btn-angle {
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;

    &:after {
        position: absolute;
        right: 1rem;
        top: 50%;
        content: '';
        margin-top: -6px;
        border-right: 1px solid $body-color;
        border-top: 1px solid $body-color;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
    }

    &-white {
        @extend .btn-angle;

        &:after {
            border-color: $white;
        }
    }
}

.btn-white {
    @include button-variant($white, $body-color);
    border: 1px solid $body-color;
}

.btn-contact {
    @include button-outline-variant($white);
    @extend .btn-angle-white;
    min-width: 220px;

    &:after {
        border-color: $white;
        transition: $transition-base;
    }

    @include hover {
        &:after {
            border-color: #181a1c;
        }
    }
}

.accordion-trigger {
    position: relative;
    display: block;
    border-bottom: 2px solid $gray-300;
    padding-left: 0;
    padding-right: 1.25rem;
    padding-top: 0;
    padding-bottom: 1rem;
    width: 100%;
    text-align: left;
    font-family: $font-family-sawarabi-mincho;
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    transition: $transition-base;

    &:after {
        content: '';
        position: absolute;
        right: .5rem;
        top: 50%;
        display: block;
        margin-top: -15px;
        border-right: 2px solid $gray-300;
        border-top: 2px solid $gray-300;
        width: 15px;
        height: 15px;
        transition: $transition-base;
    }

    &[aria-expanded="false"]:after {
        transform: rotate(45deg);
    }

    &[aria-expanded="true"]:after {
        transform: rotate(-45deg);
    }

    @include hover {
        opacity: 0.5;
    }
}

.btn-accordion {
    position: relative;
    padding-right: 1.5rem;
    font-size: rem(24);

    &:after {
        content: '';
        position: absolute;
        right: .75rem;
        top: 50%;
        display: block;
        border-right: 2px solid $white;
        border-top: 2px solid $white;
        width: 15px;
        height: 15px;
        transition: $transition-base;
    }

    &[aria-expanded="false"]:after {
        margin-top: -10px;
        transform: rotate(45deg);
    }

    &[aria-expanded="true"]:after {
        margin-top: -5px;
        transform: rotate(-45deg);
    }
}
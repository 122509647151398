.nav-anchor {
    .nav-link {
        padding-left: 0;
        padding-right: 0;
        color: $body-color;
        transition: $transition-base;

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5em;
            margin-top: -4px;
            width: 8px;
            height: 8px;
            border-right: 1px solid #b9c2c8;
            border-top: 1px solid #b9c2c8;
            transform: rotate(45deg);
        }

        @include hover {
            opacity: 0.5;   
        }
    }
}
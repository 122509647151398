.product-item {
    position: relative;
    display: block;
    color: $body-color;
    font-size: rem(14);
    transition: $transition-base;

    @include hover {
        text-decoration: none;
        opacity: 0.5;
    }

    img {
        width: auto;
        height: auto;
    }

    .product-item__rank {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        padding-left: 0.5rem;
        padding-top: 0.125rem;
        color: $white;
        font-family: $font-family-sawarabi-mincho;
        font-size: rem(18);

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 45px 45px 0 0;
            border-color: #898989 transparent transparent transparent;
        }

        &--1:before {
            border-color: $orange transparent transparent transparent;           
        }

        &--2:before {
            border-color: $yellow transparent transparent transparent;           
        }

        &--3:before {
            border-color: $blue transparent transparent transparent;           
        }
    }
}
.footer-banner {
    margin-left: auto;
    margin-top: 6rem;
    max-width: 700px;
}

.footer {
    margin-top: 4rem;
}

.footer-inner {
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #181a1c;
    color: $white;
    font-size: rem(14);

    .footer-link {
        color: inherit;
        text-decoration: underline;

        @include hover {
            text-decoration: none;
        }
    }

    .nav-anchor {
        .nav-link {
            color: $white;
            font-size: rem(16);
    
            &:before {
                border-color: $white;

                @include media-breakpoint-down(sm) {
                    content: none;
                }
            }
        }
    }
}

.footer-copyright {
    margin-bottom: 0;
    padding: 1rem;
    background-color: $black;
    color: $white;
    text-align: center;
    font-size: rem(12);

    @include media-breakpoint-down(sm) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
﻿//
// Page
//

.itemimage-thumbnail {
    position: relative;
    display: block;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    span {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
        }
    }

    &--mw-100 {
        span {
            img {
                max-width: 100%;
            }
        }
    }
}

#M_price2 {
    // max-width: 6rem;
    max-width: 5rem;
    font-weight: $font-weight-bold;
    color: $warning;
    text-align: left;
}

.option {
    select {
        @extend .custom-select;
        @extend .mb-3;
        max-width: 100%;
        width: auto;
    }
}

input[name="amount"],
.quantity input[type="number"] {
    @extend .form-control;
    display: inline-block;
    width: 3rem;
    text-align: center;
}

#M_group,
#recent {
    margin-left: -$grid-gutter-width / 4;
    margin-right: -$grid-gutter-width / 4;
    font-size: rem(14);

    > tbody > tr:nth-child(n + 2) {
        display: none;
    }

    td {
        &.itemBox {
            @extend .mb-5;
            padding-left: $grid-gutter-width / 4;
            padding-right: $grid-gutter-width / 4;
        }

        &.itemImg {
            padding-bottom: 1rem;
        }

        &.line {
            display: none;
        }
    }

    p {
        text-align: left;

        &.name {
            margin-bottom: 1rem;
        }

        &.price {
            color: $warning;
        }
    }

    a {
        color: $body-color;
    }
}

#group {
    h3,
    h4 {
        display: none;
    }

    .name img,
    .spe {
        @extend .sr-only;
    }

    .item {
        @extend .row;
        @extend .no-gutters;
        @extend .list-unstyled;
        font-size: rem(13);
    
        li {
            @extend .col-4;
            @extend .px-2;
            @extend .mb-5;
        }

        a {
            display: block;
            color: $body-color;
            transition: $transition-base;

            @include hover {
                text-decoration: none;
                opacity: 0.5;
            }
        }
    
        .itemImg {
            @extend .text-center;
            @extend .mb-3;
        }
    }
}

.itemHead {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    border-top: 1px solid $border-color;
    background-color: $light;
    font-size: rem(13);

    .nav {
        .nav-item:not(:last-child) {
            border-right: 1px solid $border-color;
        }

        a {
            color: $body-color;
        }
    }
}

.searchHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;
    background-color: $light;
    font-size: rem(13);

    #item_list_limit {
        @extend .custom-select;
        width: auto;
        font-size: $font-size-sm;
    }
    
    .viewSelect {
        flex-shrink: 0;
    }
}

#thumb_images {
    #list_item {
        @extend .list-unstyled;

        a {
            @extend .product-item;
        }

        .price {
            color: $warning;

            em {
                font-style: normal;
            }
        }
    }

    &.listGrid {
        #list_item {
            @extend .row;
        
            li {
                @extend .col-6;
                @extend .mb-5;
            }
        
            .itemImgWrap {
                @extend .text-center;
                @extend .mb-3;
            }
        }
    }

    &.listBox {
        #list_item {
            .itemBox {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
            }

            .floatL {
                margin-right: 1rem;
            }

            .price {
                margin-bottom: 0;
            }
        }
    }
}

#M_topicPathWrap,
#topicPath {
    @extend .list-unstyled;
    @extend .mb-3;
    color: $gray-600;

    ul {
        @extend .list-unstyled;
    }

    li {
        display: inline;
    }

    a {
        color: $gray-600;

        @include hover {
            color: $gray-600;
        }
    }
}

input[name="prize1"] {
    @extend .form-control;
    max-width: 350px;
}

#allcategory {
    @extend .custom-select;
    max-width: 350px;
}

input[name="money1"],
input[name="money2"] {
    @extend .form-control;
    display: inline-block;
    width: auto;
}

.basket-image img {
    max-width: 170px;
    width: auto;

    @include media-breakpoint-down(sm) {
        max-width: 70px;
    }
}

.basket_option_mulopt_box {
    margin-left: 0 !important;

    select[name="mulopt"] {
        @extend .custom-select;
        @extend .mb-3;
        display: inline-block;
        width: auto;
    }
}

.basket-option {
    .option {
        @extend .list-unstyled;

        select {
            @extend .custom-select;
            @extend .mb-3;
            display: inline-block;
            width: 100%;
        }
    }
}

#add_img {
    @extend .row;
    @extend .no-gutters;
    @extend .list-unstyled;
    @extend .justify-content-center;

    li {
        @extend .col-4;
        @extend .px-1;

        p {
            @extend .sr-only;
        }
    }
}

ul.M_pager,
.pager {
    padding-left: 0;
    list-style: none;
    text-align: center;

    li {
        display: inline-block;
        font-size: rem(14);

        a {
            display: inline-block;
            padding: .25em .5em;
            border: 1px solid $gray-300;
            background-color: $gray-100;
            color: $body-color;
        }

        &.active {
            padding: .25em .5em;
        }
    }
}

.table-product {
    border-bottom: $table-border-width solid $table-border-color;

    th,
    td {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    th {
        @include media-breakpoint-up(md) {
            font-size: rem(18);
        }
    }
}
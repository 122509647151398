.main-visual-carousel-container {
    display: none;
}

.main-visual-carousel {
    position: relative;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    // Slick dots
    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        > li {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            display: flex;
            align-items: center;

            button {
                @include image-replacement();
                border-radius: 50%;
                background-color: #8e8e8e;
                width: rem-calc(10);
                height: rem-calc(10);
                cursor: pointer;
                border: none;
                padding: 0;
            }

            &.slick-active {
                button {
                    background-color: $white;
                }
            }
        }
    }
}

.product-carousel {
    position: relative;

    // Slick arrows
    .slick-arrow {
        @include image-replacement();
        cursor: pointer;
        outline: 0;
        display: block;
        width: 30px;
        height: 30px;
        background-color: rgba(#444, 0.8);
        position: absolute;
        z-index: 2;
        top: 50%;
        margin-top: -15px;
        border: none;
        padding: 0;
        transition: $btn-transition;
        
        @include hover {
            background-color: #444;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 15px;
            height: 15px;
            border-top: 1px solid #FFF;
            border-left: 1px solid #FFF;
        }

        &.slick-prev {
            left: 0;
            margin-left: -15px;

            @include media-breakpoint-down(sm) {
                margin-left: -7px;
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
                margin-left: 5px;
            }
        }

        &.slick-next {
            right: 0;
            margin-right: -15px;

            @include media-breakpoint-down(sm) {
                margin-right: -7px;
            }

            &:after {
                transform: translate(-50%, -50%) rotate(135deg);
                margin-left: -5px;
            }
        }
    }
}
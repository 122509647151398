﻿//
// Common
//

.link-underline {
    color: $body-color;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}
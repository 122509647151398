.alert-danger-heading {
    border-right: 1px dashed $danger;
}

.alert-danger-dark {
    @include alert-variant(#d22828, #d22828, $white);

    a {
        color: $white;
        text-decoration: underline;

        @include hover {
            text-decoration: none;
        }
    }
}
//
// Header
//

.header {
	margin-bottom: 2rem;
	border-bottom: 1px solid #e9e9e9;
	height: 80px;
	background-color: $white;

	@include media-breakpoint-down(sm) {
		position: fixed;
		left: 0;
		right: 0;
		z-index: $zindex-fixed;
		margin-bottom: 0;
		width: 100%;
		height: 55px;
	}
}

.header-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;

	@include media-breakpoint-down(sm) {
		justify-content: center;
	}
}

// Logo
.header-logo {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;

	@include media-breakpoint-down(sm) {
		img {
			max-height: 30px;
		}
	}
}

.search-form {
	position: relative;

	&__control {
		border: none;
		border-radius: 0;
		width: 220px;
		font-size: rem(14);
		color: #a7a7a7;
		background-color: #f8f8f8;
	}

	&__search {
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -10px;
		width: 20px;
		height: 20px;
		transition: $transition-base;

		@include hover {
			opacity: 0.5;
		}
	}
}

.header-cart-link {
	display: block;
	font-size: rem(14);
	font-weight: $font-weight-bold;
	color: $body-color;
	transition: $transition-base;

	@include hover {
		color: $body-color;
		text-decoration: none;
		opacity: 0.5;
	}
}

.header-menu-button {
	position: absolute;
	left: $grid-gutter-width / 2;
	padding: 0;
	width: 40px;
	height: 40px;

	&-icon {
		span {
			position: absolute;
			top: 18px;
			display: block;
			width: 35px;
			height: 3px;
			background-color: $black;
			transition: $transition-base;
		}
	}

	&[aria-expanded="false"] {
		.header-menu-button-icon__top {
			top: 8px;
			transform: rotate(0);
		}

		.header-menu-button-icon__middle {
			top: 18px;
		}

		.header-menu-button-icon__bottom {
			top: 28px;
			transform: rotate(0);
		}
	}

	&[aria-expanded="true"] {
		.header-menu-button-icon__top {
			transform: rotate(45deg);
		}

		.header-menu-button-icon__middle {
			background-color: transparent;
		}

		.header-menu-button-icon__bottom {
			transform: rotate(-45deg);
		}
	}
}

.header-utility {
	position: absolute;
	right: $grid-gutter-width / 2;
	display: flex;
	padding-left: 0;
	list-style: none;

	&__item {
		padding-left: 1rem;

		img {
			max-height: 25px;
		}
	}
}

.overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	margin-top: 1px;
	padding-bottom: 5rem;
	width: 100%;
	height: calc(100vh - 55px);
	background-color: rgba($black, .5);
	overflow: auto;
	-webkit-overflow-scrolling: touch
}

.header-menu {
	padding: 1rem;
	width: 90%;
	background-color: $white;
}

.badge-quantity {
	position: absolute;
	right: -.5rem;
	top: -.75rem;
	font-size: rem(12);
}